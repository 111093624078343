import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"
import queryString from 'query-string'

import Layout from "../components/layout"
import Seo from "../components/seo"
import CheckinForm from "../components/CheckinForm"

type VenueDetails = {
  id: number,
  name: string,
  checkinBlurb: string
}

async function sleep(millis: number) {
  await new Promise(res => setTimeout(res, millis));
}

async function getDetailsFromVenueId({ venueId }: { venueId: string }): Promise<VenueDetails> {
  await sleep(700)
  return {
    id: 42,
    name: "Al's Place",
    checkinBlurb: "We are glad to have you! Before seating, please provide us with the details required based on Covid-19 regulations."
  }
}

type DataProps = {
  site: {
    buildTime: string
  }
}

const CheckinView = ({ venueDetails }: { venueDetails: VenueDetails }) => {

  return <>
    <section>
      <h1>Welcome to {venueDetails.name}</h1>
      <p>{venueDetails.checkinBlurb}</p>
    </section>
    <section>
      <CheckinForm venueId={venueDetails.id} />
    </section>
  </>
}

const CheckinWithVenue = ({ location }) => {

  const [venueDetails, setVenueDetails] = React.useState<VenueDetails | undefined | null>(undefined)
  React.useEffect(() => {
    const parsed = queryString.parse(location.search)
    const venueId = parsed.q as string | undefined;
    if (venueId) {
      const fetchVenueDetails = async () => {
        setVenueDetails(await getDetailsFromVenueId({ venueId }))
      }
      fetchVenueDetails()
    } else {
      setVenueDetails(null) // no venue in query params, so no details
    }
  }, [])
  if (venueDetails === null) {
    return <p>No venue details, did you scan a valid code?</p>
  } else if (venueDetails) {
    return <CheckinView venueDetails={venueDetails} />
  } else {
    return <p>Loading...</p>
  }
}

// https://www.gatsbyjs.com/docs/location-data-from-props/

const Checkin: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
  <Layout>
    <Seo title="Checkin" />
    <CheckinWithVenue location={location} />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Checkin

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
