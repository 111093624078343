import React, { ReactChild, ReactNode } from "react"
import { Formik, Form, Field, ErrorMessage as FormikErrorMessage } from 'formik'
import "./checkinForm.css"

type FormValues = {
  firstName: string,
  lastName: string,
  email: string,
  closeContact: string,
  testedPositive: string,
  symptoms: string,
  temperature: string,
  signature: string
}

function ErrorMessage({ name }) {
  return <FormikErrorMessage name={name} className="error" component="div"/>
}

function FieldSet({ legend, children }: { legend: string, children: ReactNode }) {

  return <fieldset>
    <legend>{legend}</legend>
    {children}
  </fieldset>
}

function FieldGroup({ ariaLabel, children }: { ariaLabel: string, children: ReactNode }) {
  return <div className="fieldGroup" role="group" aria-labelledby={ariaLabel}>
    {children}
  </div>
}

function YesNoField({ name, label }) {
  return <>
    <label className="fieldLabel" htmlFor={name}>{label}</label>
    <label className="yesLabel">
      <Field type="radio" name={name} value="yes" />
      Yes
    </label>
    <label>
      <Field type="radio" name={name} value="no" />
      No
    </label>
    <ErrorMessage name={name} />
  </>
}

const requiredFields = [
  "firstName",
  "lastName",
  "closeContact",
  "testedPositive",
  "symptoms",
  "temperature",
  // "signature"
];

export default function({ venueId }: { venueId: number }) {
  const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    email: '',
    closeContact: '',
    testedPositive: '',
    symptoms: '',
    temperature: '',
    signature: ''
  }
  return <Formik
    initialValues={initialValues}
    validate={values => {
      const errors: Partial<FormValues> = {};
      if (values.email
        && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = 'Invalid email address';
      }
      for (const requiredField of requiredFields) {
        if (!values[requiredField]) {
          errors[requiredField] = 'Required';
        }
      }
      return errors;
    }}
    onSubmit={(values, { setSubmitting }) => {
      const send = async () => {
        try {
          const response = await window.fetch("https://rumbling-marvelous-circle.glitch.me/visits", {
            method: "POST",
            mode: 'no-cors',
            headers: {
              'content-type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams(values).toString()
          });
          console.log('response', response);
          alert("Thank you! Your visit is recorded.");
        } finally {
          setSubmitting(false);
        }
      }
      send()
    }}
  >
    {({ isSubmitting, isValid }) => (
      <Form className="checkinForm">
        <FieldSet legend="Your contact details">

          <FieldGroup ariaLabel="first-name">
            <label className="fieldLabel" htmlFor="firstName">First name</label>
            <Field className="field" type="text" name="firstName" id="firstName" autoComplete="given-name" />
            <ErrorMessage name="firstName" />
          </FieldGroup>

          <FieldGroup ariaLabel="last-name">
            <label className="fieldLabel" htmlFor="lastName">Last name</label>
            <Field className="field" type="text" name="lastName" id="lastName" autoComplete="family-name" />
            <ErrorMessage name="lastName" />
          </FieldGroup>

          <FieldGroup ariaLabel="email">
            <label className="fieldLabel" htmlFor="email">What is your email address? (optional)</label>
            <Field className="field" type="email" name="email" id="email" autoComplete="email" />
            <ErrorMessage name="email" />
          </FieldGroup>
        </FieldSet>

        <FieldSet legend="Covid Questionnaire">
          <FieldGroup ariaLabel="close-contact">
            <YesNoField
              name="testedPositive"
              label="Have you tested positive for Covid-19 in the last 10 days?"
            />
            <YesNoField
              name="closeContact"
              label="Have you had close contact with or cared for someone diagnosed with Covid-19 within the last 10 days?"
            />
            <YesNoField
              name="symptoms"
              label={`Do you have any of the following new
                or worsening symptoms:
                Fever, Cough, Shortness of breath, Sore throat,
                Unexplained fatigue, Nausea/vomitting/diarrhoea/abdominal pain,
                Body ache, Loss of taste or smell, Severe redness of eyes?
              `}
            />
          </FieldGroup>

          <FieldGroup ariaLabel="temperature">
            <label className="fieldLabel" htmlFor="temperature">Please enter your temperature (degree Celsius)</label>
            <Field className="field" type="number" min="33" max="45" step="0.1" name="temperature" id="temperature" />
            <ErrorMessage name="temperature" />
          </FieldGroup>

          <FieldGroup ariaLabel="signature">
            TODO: signature (including reset button)
          </FieldGroup>
        </FieldSet>

        <button type="submit" disabled={isSubmitting || !isValid}>
          Submit
        </button>
        { !isValid && <div className="error">Please check above: Some details are missing or invalid.</div>}
      </Form>
    )}
  </Formik>
}
